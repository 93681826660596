@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500&display=swap);
.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  padding: 0;
  margin: 0;
}
.navbar {
  width: 100%;
  height: 100px;
  background-color: #121619;
  display: flex;
  flex-direction: row;
}

.navbar .leftSide {
  flex: 50% 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 100px;
  padding-top: 10px;
}

.navbar .leftSide img {
  width: 80px;
}

.navbar .rightSide {
  flex: 50% 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar a {
  color: white;
  text-decoration: none;
  margin: 20px;
}

.navbar .rightSide button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.navbar .rightSide svg {
  font-size: 40px;
}

.navbar #open {
  padding-left: 0px;
}
.navbar #open img {
  display: none;
}

.navbar #close img {
  display: inherit;
}

.navbar #open .hiddenLinks {
  display: inherit;
  margin-left: 30px;
}

.navbar #close .hiddenLinks {
  display: none;
}

.navbar #open a {
  width: 70px;
  margin: 5px;
}
@media only screen and (max-width: 900px) {
  .navbar .rightSide a {
    width: 70px;
  }
  .navbar .leftSide {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .navbar .rightSide a {
    display: none;
  }

  .navbar .rightSide {
    justify-content: flex-end;
    padding-right: 50px;
  }

  .navbar .rightSide button {
    display: inherit;
  }
}

@media only screen and (min-width: 600px) {
  .navbar .rightSide button {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }
}

.footer {
  width: 100%;
  height: 200px;
  background-color: #121619;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
}

.socialMedia svg {
  color: white;
  margin: 20px;
  font-size: 70px;
  cursor: pointer;
}

.footer p {
  color: white;
}
.home {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  -webkit-animation: fadeIn 3s;
          animation: fadeIn 3s;
}

.headerContainer {
  width: auto;
  margin-left: 50px;
}

.headerContainer h1 {
  justify-content: center;
  align-items: center;
  font-size: 90px;
  height: 60px;
  font-weight: 50;
  color: black;
}

.headerContainer p {
  padding: 0;
  font-size: 40px;
  font-weight: lighter;
  color: black;
}

.headerContainer button {
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  background-color: #121619;
  height: 50px;
  width: 180px;
  color: white;
  text-decoration: none;
  font-size: 20px;
}

.headerContainer button:hover {
  background-color: #07090a;
  cursor: pointer;
  transition: 0.3s ease-in;
}

@media only screen and (max-width: 650px) {
  .home {
    justify-content: center;
    align-items: center;
  }

  .headerContainer {
    margin-left: 0px;
    border-radius: 10px;
    padding: 10px;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #121619;
    color: white;
  }

  .headerContainer h1 {
    font-size: 40px;
    height: 30px;
    color: white;
  }

  .headerContainer p {
    font-size: 30px;
    color: white;
  }

  .headerContainer button {
    background-color: white;
    color: #121619;
    margin-bottom: 30px;
  }

  .headerContainer button:hover {
    background-color: rgb(225, 225, 225);
    color: #121619;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.collapsible {
  background-color: #777;
  color: white;
  cursor: pointer;
  padding: 10px;
  width: 20%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.active,
.collapsible:hover {
  background-color: #555;
}

.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  width: 50%;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
}

.breed {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Quicksand', sans-serif;
}
.breed .breedTitle {
  font-family: 'Quicksand', sans-serif;
  font-size: 60px;
}

.breedList {
  width: 70vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}

.breedInfo {
  font-size: 30px;
  margin-left: 10px;
}

/* breed ITEM STYLING */

.breedItem {
  border-radius: 15px;
  width: 300px;
  height: 350px;
  margin: 20px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}
.breedItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.breedItem div {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 700px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.breedItem h1,
.breedItem p {
  margin-left: 20px;
}

@media only screen and (max-width: 1300px) {
  .breedList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .breedList {
    grid-template-columns: 1fr;
  }
}

/* CSS */

.button-89 {
  --b: 3px; /* border thickness */
  --s: 0.45em; /* size of the corner */
  --color: #373b44;

  padding: calc(0.5em + var(--s)) calc(0.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background: conic-gradient(
      from 90deg at var(--b) var(--b),
      #0000 90deg,
      var(--color) 0
    )
    var(--_p) var(--_p) / calc(100% - var(--b) - 2 * var(--_p))
    calc(100% - var(--b) - 2 * var(--_p));
  transition: 0.3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: 0.6em;
  font-size: 16px;

  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-family: 'Quicksand', sans-serif;
}

.button-89:hover,
.button-89:focus-visible {
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: 0.05em;
}

.button-89:active {
  background: var(--color);
  color: #fff;
}

.about {
  width: 100%;
  height: 135vh;
  display: flex;
  flex-direction: column;
  font-family: 'Quicksand', sans-serif;
}

.aboutTop {
  width: 100%;
  height: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-animation: fadeIn 2s;
          animation: fadeIn 2s;
  text-align: center;
}

.aboutBottom {
  display: flex;
  flex-direction: column;
  height: 40%;
  align-items: center;
  margin-top: 100px;
}

.aboutBottom p {
  width: 1200px;
}

.about .aboutBottom h1 {
  font-weight: 400;
  font-size: 70px;
  color: black;
  height: 30px;
  display: flex;
  justify-content: center;
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px);
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.contact {
  width: 100%;
  height: 90vh;
  display: flex;
  font-family: 'Quicksand', sans-serif;
}

.contact .leftSide {
  height: 100%;
  flex: 50% 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact .rightSide {
  height: 100%;
  flex: 50% 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact .rightSide h1 {
  font-weight: 10;
  font-size: 50px;
  margin-left: 30px;
}

form {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  padding: 30px;
}

form input {
  height: 40px;
  width: 80%;
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
}

form textarea {
  margin-top: 15px;
  height: 70px;
  width: 80%;
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
  font-family: 'Quicksand', sans-serif;
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-weight: bold;
  color: black;
  font-family: 'Quicksand', sans-serif;
}
input::placeholder,
textarea::placeholder {
  font-weight: bold;
  color: black;
  font-family: 'Quicksand', sans-serif;
}
form input:focus,
textarea:focus {
  outline: none;
}

form label {
  margin-top: 15px;
  color: grey;
}

form button {
  margin-top: 40px;
  width: 140px;
  height: 40px;
  border: none;
  background-color: #121619;
  color: whitesmoke;
  font-size: 15px;
  cursor: pointer;
  font-family: 'Quicksand', sans-serif;
}

