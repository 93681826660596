@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500&display=swap');

.about {
  width: 100%;
  height: 135vh;
  display: flex;
  flex-direction: column;
  font-family: 'Quicksand', sans-serif;
}

.aboutTop {
  width: 100%;
  height: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: fadeIn 2s;
  text-align: center;
}

.aboutBottom {
  display: flex;
  flex-direction: column;
  height: 40%;
  align-items: center;
  margin-top: 100px;
}

.aboutBottom p {
  width: 1200px;
}

.about .aboutBottom h1 {
  font-weight: 400;
  font-size: 70px;
  color: black;
  height: 30px;
  display: flex;
  justify-content: center;
  transform: translateY(-40px);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
