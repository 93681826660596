@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500&display=swap');

.breed {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Quicksand', sans-serif;
}
.breed .breedTitle {
  font-family: 'Quicksand', sans-serif;
  font-size: 60px;
}

.breedList {
  width: 70vw;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}

.breedInfo {
  font-size: 30px;
  margin-left: 10px;
}

/* breed ITEM STYLING */

.breedItem {
  border-radius: 15px;
  width: 300px;
  height: 350px;
  margin: 20px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}
.breedItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.breedItem div {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 700px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.breedItem h1,
.breedItem p {
  margin-left: 20px;
}

@media only screen and (max-width: 1300px) {
  .breedList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .breedList {
    grid-template-columns: 1fr;
  }
}
