@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500&display=swap');

.contact {
  width: 100%;
  height: 90vh;
  display: flex;
  font-family: 'Quicksand', sans-serif;
}

.contact .leftSide {
  height: 100%;
  flex: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact .rightSide {
  height: 100%;
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact .rightSide h1 {
  font-weight: 10;
  font-size: 50px;
  margin-left: 30px;
}

form {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  padding: 30px;
}

form input {
  height: 40px;
  width: 80%;
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
}

form textarea {
  margin-top: 15px;
  height: 70px;
  width: 80%;
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
  font-family: 'Quicksand', sans-serif;
}
input::placeholder,
textarea::placeholder {
  font-weight: bold;
  color: black;
  font-family: 'Quicksand', sans-serif;
}
form input:focus,
textarea:focus {
  outline: none;
}

form label {
  margin-top: 15px;
  color: grey;
}

form button {
  margin-top: 40px;
  width: 140px;
  height: 40px;
  border: none;
  background-color: #121619;
  color: whitesmoke;
  font-size: 15px;
  cursor: pointer;
  font-family: 'Quicksand', sans-serif;
}
